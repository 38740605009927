<template>
  <div>
    <!-- 显示合同详情 -->
    <Row v-if="chooseRightContractId" class="text-center p-t-20">
      <i-col :span="publisherId===21?9:7">
        <div style="width:100%;height:250px;" id="addUpAmountChartReport"></div>
      </i-col>
      <i-col span="5">
        <p class="text-14">投放次数</p>
        <p class="text-26 text-blue p-t-50"><b>{{contractCode}} 次</b></p>
      </i-col>
      <i-col span="5" v-if="publisherId !== 21">
        <p class="text-14 m-b-20">历史平均折扣率</p>
        <i-circle :percent="parseInt(contractDiscountRate)">
          <span class="demo-Circle-inner" style="font-size:24px">{{contractDiscountRate}}%</span>
        </i-circle>
      </i-col>
      <i-col  :span="publisherId===21?10:7">
        <div style="width:100%;height:250px;" id="buyTopStationChartReport"></div>
      </i-col>
    </Row>

    <div v-show="chooseRightContractId===null" style="width:100%;height:280px;" id="contractChartReport"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getStorage } from '@/utils/storage'
import { listContractNumberAndAmountGroupByDate, listAdvertiserContractDiscountRate, listAdvertiserContractCode, listAdvertiserBuyTopStation, listAdvertiserAddUpAmount } from '@/api/dw/datav'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      dataSourcePie: null,

      contractDiscountRate: 0,
      contractCode: 0,
      addUpAmountDataSourcePie: null,
      buyTopStationDataSourcePie: null
    }
  },
  mounted () {
    this.loadReportData()
  },
  methods: {
    loadReportData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        orderType: this.orderType,
        companyId: this.companyId
      }

      listContractNumberAndAmountGroupByDate(queryModel).then(res => {
        const xLabels = []
        const yData1 = []
        const yData2 = []
        const yData3 = []

        res.forEach(element => {
          if (!xLabels.includes(element.x)) {
            xLabels.push(element.x)
          }

          if (element.s === '订单数') {
            yData1.push(element.y)
          } else if (element.s === '合同数') {
            yData2.push(element.y)
          } else if (element.s === '合同额') {
            yData3.push(element.y)
          }
        })

        this.initReportChart(xLabels, yData1, yData2, yData3)
      })
    },
    initReportChart (xLabels, yData1, yData2, yData3) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('contractChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('contractChartReport'))
          const colors = ['#5470C6', '#91CC75', '#EE6666']
          const option = {
            color: colors,
            title: {
              text: '订单、合同、签约额',
              textStyle: {
                color: this.reportFontColor === '#fff' ? '#dcdee2' : '#515a6e'
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            grid: {
              left: 70,
              right: 100,
              top: 70,
              bottom: 30
            },
            legend: {
              data: ['订单数', '合同数', '合同额'],
              right: 50,
              textStyle: {
                color: this.reportFontColor
              }
            },
            xAxis: [
              {
                type: 'category',
                axisTick: {
                  alignWithLabel: true
                },
                axisLabel: {
                  color: '#c5c8ce'
                },
                data: xLabels
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: '订单数',
                position: 'right',
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[0]
                  }
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    opacity: 0.1
                  }
                }
              },
              {
                type: 'value',
                name: '合同数',
                position: 'right',
                offset: 70,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[1]
                  }
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    opacity: 0.1
                  }
                }
              },
              {
                type: 'value',
                name: '合同额(元)',
                position: 'left',
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[2]
                  }
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    opacity: 0.1
                  }
                }
              }
            ],
            series: [
              {
                name: '订单数',
                type: 'bar',
                data: yData1
              },
              {
                name: '合同数',
                type: 'bar',
                yAxisIndex: 1,
                data: yData2
              },
              {
                name: '合同额',
                type: 'line',
                smooth: true,
                yAxisIndex: 2,
                data: yData3
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    },
    loadContractHistoryData () {
      const queryModel = {
        advertiserId: this.chooseRightRowData.advertiserId
      }

      listAdvertiserContractDiscountRate(queryModel).then(res => {
        const discount = (res[0].value * 100).toFixed(1)

        this.contractDiscountRate = discount
      })
      listAdvertiserContractCode(queryModel).then(res => {
        this.contractCode = res[0].value
      })
      listAdvertiserBuyTopStation(queryModel).then(res => {
        const top5Station = res.splice(0, 5)
        const xData = top5Station.map(x => x.x)
        const yData = top5Station.map(x => x.y)

        this.initBuyTopStationChartReport(xData, yData)
      })
      listAdvertiserAddUpAmount(queryModel).then(res => {
        const xData = res.map(x => x.x)
        const yData = res.map(x => x.y)

        this.initAddUpAmountChartReport(xData, yData)
      })
    },
    initBuyTopStationChartReport (xData, yData) {
      if (this.buyTopStationDataSourcePie != null && this.buyTopStationDataSourcePie !== '' && this.buyTopStationDataSourcePie !== undefined) {
        this.buyTopStationDataSourcePie.dispose()
      }

      this.$nextTick(() => {
        this.buyTopStationDataSourcePie = echarts.init(document.getElementById('buyTopStationChartReport'))

        const option = {
          color: ['#2b85e4'],
          title: {
            text: '历史投放量Top5站点(块/天)',
            textStyle: {
              color: this.reportFontColor,
              fontWeight: 'normal',
              fontSize: 14
            }
          },
          xAxis: {
            type: 'category',
            data: xData,
            axisLabel: {
              color: '#c5c8ce'
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true
            },
            splitLine: {
              show: false
            }
          },
          grid: {
            top: '20%',
            left: '5%',
            right: '5%',
            bottom: 0,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999'
              }
            }
          },
          series: [
            {
              data: yData,
              type: 'bar',
              label: {
                show: true,
                position: 'top',
                color: this.reportFontColor,
                rich: {
                  color: this.reportFontColor
                }
              }
            }
          ]
        }

        this.buyTopStationDataSourcePie.setOption(option, true)
        const that = this
        window.addEventListener('resize', function () {
          that.buyTopStationDataSourcePie.resize()
        })
      })
    },
    initAddUpAmountChartReport (xData, yData) {
      if (this.addUpAmountDataSourcePie != null && this.addUpAmountDataSourcePie !== '' && this.addUpAmountDataSourcePie !== undefined) {
        this.addUpAmountDataSourcePie.dispose()
      }

      this.$nextTick(() => {
        this.addUpAmountDataSourcePie = echarts.init(document.getElementById('addUpAmountChartReport'))

        const option = {
          color: ['#2b85e4'],
          title: {
            text: '历史成交金额',
            textStyle: {
              color: this.reportFontColor,
              fontWeight: 'normal',
              fontSize: 14
            }
          },
          grid: {
            top: '20%',
            left: '5%',
            right: '5%',
            bottom: 0,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xData,
            axisLabel: {
              color: '#c5c8ce',
              formatter: function (value, index) {
                return value.substr(0, 10)
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true
            },
            splitLine: {
              show: false
            }
          },
          series: [
            {
              data: yData,
              type: 'line',
              areaStyle: {}
            }
          ]
        }

        this.addUpAmountDataSourcePie.setOption(option, true)
        const that = this
        window.addEventListener('resize', function () {
          that.addUpAmountDataSourcePie.resize()
        })
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    orderType () {
      return this.$store.state.situationWeb.orderType
    },
    chooseRightContractId () {
      return this.$store.state.situationWeb.rightTableId
    },
    chooseRightRowData () {
      return this.$store.state.situationWeb.rightTableRowBean
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadReportData()
      }
    },
    companyId () {
      this.loadReportData()
    },
    orderType () {
      this.loadReportData()
    },
    chooseRightContractId (val) {
      if (val) {
        // 开始加载合同历史数据
        this.loadContractHistoryData(val)
      } else {
        this.$nextTick(() => {
          this.dataSourcePie.resize()
        })
      }
    }
  }
}
</script>
